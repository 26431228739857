<template>
  <div>
    <page-navigate></page-navigate>
    <section class="bg">
      <img class="bg-404" src="../assets/image/404/404.svg" alt="" />
      <h2>The page you are visiting does not exist.</h2>
      <a href="/" class="back-btn"> Back Home </a>
    </section>
  </div>
</template>

<script>
export default{

}

</script>

<style scoped>
.bg {
  /* width: 100%; */
  height: calc(100vh - 88rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/image/404/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-404 {
  width: 534rem;
  height: 200rem;
}
h2 {
  margin-top: 40rem;
  color: #232222;
  text-align: center;
  font-family: Gilroy;
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.back-btn {
  margin-top: 40rem;
  display: flex;
  width: 244rem;
  padding: 12rem 24rem;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  border-radius: 4rem;
  border: 1px solid var(--ebc--, #5d2333);
  background: #5d2333;
  color: #fff;
  font-family: Gilroy;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 996px) {
  .bg {
      height: calc(100vh - 80px);
  }
  .bg-404 {
    width: 400rem;
    height: unset;
  }
}
</style>